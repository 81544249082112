import React, {useEffect} from 'react';

const FlashLinkContainer = (props) => {

    return (
        <div className="flashlink-item-container d-flex align-items-center">
            <div className="flashlink-item-icon">
                {props.link.icon ? (
                    <img src={props.link.icon} alt="Icon"/>
                ) : null}
            </div>
            <p className="ms-3 flex-grow-1">{props.link.name}</p>
        </div>
    );
}

export default FlashLinkContainer;