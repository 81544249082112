import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router";
import {login} from "../redux/userSlice";
import {useDispatch} from "react-redux";

const GoogleAuthenticate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const redirect = query.get('redirect');
    const jwtToken = query.get('jwt');

    useEffect(() => {
        if (jwtToken) {
            dispatch(login({
                username: "",
                password: "",
                token: jwtToken
            }));

            window.localStorage.setItem('token', jwtToken);

            navigate(`/${redirect}`);
        }
    }, [jwtToken]);


    return (
        <>
        </>
    );
};

export default GoogleAuthenticate;