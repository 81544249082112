import React, { useState, useEffect, useRef } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

function GoogleMap(props) {
    const [position, setPosition] = useState({ lat: -34.6402134, lng: -58.559104 });
    const [infoWindow, setInfoWindow] = useState(null);
    const mapRef = useRef(null);

    useEffect(() => {
        const geocoder = new props.google.maps.Geocoder();
        geocoder.geocode({ 'address': props.venue ? (props.venue + "," + props.address) : props.address }, function (results, status) {
            if (status === 'OK') {
                const latitude = results[0].geometry.location.lat();
                const longitude = results[0].geometry.location.lng();
                setPosition({ lat: latitude, lng: longitude });
            }
        });
    }, [props.address, props.google.maps.Geocoder]);

    const onMapReady = (mapProps, map) => {
        mapRef.current = map;
    }

    const onMarkerClick = () => {
        if (infoWindow) {
            infoWindow.close();
        }
        const iw = new props.google.maps.InfoWindow({
            content: `<div>
                    ${props.venue ? `<strong style="color: black !important;">${props.venue}</strong>` : ``}
                  </div>
                  <div style="color: black !important;">
                    ${props.address}
                  </div>
                  <div>
                    <a href="https://www.google.com/maps?q=${position.lat},${position.lng}" target="_blank" rel="noopener noreferrer">Abrir en Google Maps</a>
                  </div>`,
            position: position,
        });
        iw.open(mapRef.current);
        setInfoWindow(iw);
    };

    return (
        <div className="map-container">
            <Map
                google={props.google}
                zoom={14}
                center={position}
                initialCenter={position}
                onReady={onMapReady}
            >
                <Marker
                    position={position}
                    onClick={onMarkerClick}
                />
            </Map>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCzZeIJOPNGcXV3axmKnMXvThDFno78IaU'
})(GoogleMap);