import { createSlice } from "@reduxjs/toolkit";

export const carroSlice = createSlice({
    name: "carro",
    initialState: {
        carro: {
            items: [],
            total: 0
        },
        expandedSearchBar: false,
    },
    reducers: {
        addItem: (state, action) => {
            state.carro.items.push(action.payload.item);
            state.carro.total += action.payload.item.price;
        },
        substractItem: (state, action) => {
            for (let i = 0; i < state.carro.items.length; i++) {
                if (state.carro.items[i].id === action.payload.item.id) {
                    state.carro.items.splice(i, 1);
                    state.carro.total -= action.payload.item.price;
                    break;
                }
            }
        },
        replaceItems: (state, action) => {
            state.carro.items = action.payload.items;
        },
        removeItem: (state, action) => {
            state.carro.total -= action.payload.item.price * state.carro.items.filter(item => item.id === action.payload.item.id).length;
            state.carro.items = state.carro.items.filter(item => item.id !== action.payload.item.id);
        },
        expandSearchBar: (state) => {
            state.expandedSearchBar = true;
        },
        collapseSearchBar: (state) => {
            state.expandedSearchBar = false;
        }
    }
});

export const { addItem, substractItem, replaceItems, removeItem, expandSearchBar, collapseSearchBar } = carroSlice.actions;

export const selectCarro = (state) => state.carro.carro;
export const selectExpandedSearchBar = (state) => state.carro.expandedSearchBar;

export default carroSlice.reducer;