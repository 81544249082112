import React, {useEffect, useState} from 'react';
import {
    Alert,
    AlertTitle,
    CircularProgress, FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ThemeProvider
} from "@mui/material";
import NavbarHomePage from "../components/NavbarHomePage";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {useNavigate} from "react-router";
import SnackbarAlert from "../components/SnackbarAlert";

const ProfileUsers = (props) => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const [snackOpen, setSnackOpen] = useState(false);
    const [snack, setSnack] = useState({
        severity: 'success',
        message: 'Usuario creado con éxito'
    });

    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        name: '',
        surname: '',
        email: '',
        documentNumber: '',
        phone: '',
        birthDate: new Date(),
        genero: ""
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const fetchProfile = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile-users`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            }
        });

        const data = await response.json();
        if (data.status === "success") {
            setValues({...values, ...data.profile, user_tel: data.profile.user_tel === 0 ? "" : data.profile.user_tel, birth_date: new Date(data.profile.birth_date) > new Date("1900-01-01") ? new Date(data.profile.birth_date) : ""});
            setLoading(false);
        }
    }

    const updateProfile = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/profile-users`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            },
            body: JSON.stringify(values)
        });

        const data = await response.json();
        if (data.status === "success") {
            setLoading(false);
            setSnack({
                severity: 'success',
                message: 'Perfil actualizado con éxito'
            });
            setSnackOpen(true);
        }
        else {
            setLoading(false);
            setSnack({
                severity: 'error',
                message: 'Error al actualizar el perfil'
            });
            setSnackOpen(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        updateProfile();
    }

    useEffect(() => {
        if (user.token) {
            fetchProfile();
        }
        else {
            navigate("/login");
        }
    }, [user.token]);

    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <SnackbarAlert message={snack.message} severity={snack.severity} open={snackOpen} setOpen={setSnackOpen} />
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-12">
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <h1>Mi Perfil</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <form onSubmit={submitForm}>
                                            <div className="mb-3">
                                                <TextField fullWidth label="Nombre" variant="filled" value={values?.user_name} onChange={handleChange('user_name')} />
                                            </div>
                                            <div className="mb-3">
                                                <TextField fullWidth label="Apellido" variant="filled" value={values?.user_surname} onChange={handleChange('user_surname')} />
                                            </div>
                                            <div className="mb-3">
                                                <TextField fullWidth label="Email" variant="filled" value={values?.email} onChange={handleChange('email')} disabled={true} helperText={"Si deseas modificar el email de tu cuenta, por favor contactate a info@flashpass.com.ar para que lo podamos resolver."} />
                                            </div>
                                            <div className="mb-3">
                                                <TextField fullWidth type="number" onWheel={(e) => e.preventDefault()}  label="DNI" variant="filled" value={values?.user_dni} onChange={handleChange('user_dni')} />
                                            </div>
                                            <div className="mb-3">
                                                <TextField fullWidth label="Teléfono" type="number" onWheel={(e) => e.preventDefault()}  variant="filled" value={values?.user_tel} onChange={handleChange('user_tel')} />
                                            </div>
                                            <div className="mb-3">
                                                <LocalizationProvider adapterLocale={"es"} dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Fecha de nacimiento"
                                                        value={values?.birth_date}
                                                        inputFormat={'DD/MM/YYYY'}
                                                        onChange={(newValue) => {
                                                            setValues((prevValues) => ({
                                                                ...prevValues,
                                                                birth_date: newValue,
                                                            }));
                                                        }}
                                                        renderInput={(params) => <TextField fullWidth variant="filled" {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className="mb-3">
                                                <FormControl variant="filled" fullWidth>
                                                    <InputLabel>Sexo</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Tipo"
                                                        value={values?.genero}
                                                        onChange={handleChange('genero')}
                                                    >
                                                        <MenuItem value={"M"}>Hombre</MenuItem>
                                                        <MenuItem value={"F"}>Mujer</MenuItem>
                                                        <MenuItem value={"O"}>Otros</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn w-100 btn-primary" type="submit">Guardar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </div>
    );
};

export default ProfileUsers;