import React, {useEffect, useState} from 'react';
import {
    Alert,
    AlertTitle,
    CircularProgress, FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ThemeProvider
} from "@mui/material";
import NavbarHomePage from "../components/NavbarHomePage";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import {useDispatch, useSelector} from "react-redux";
import {login, selectUser} from "../redux/userSlice";
import {useNavigate} from "react-router";
import SnackbarAlert from "../components/SnackbarAlert";

const ForgotPassword = (props) => {

    const user = useSelector(selectUser);
    const navigation = useNavigate();

    const [snackOpen, setSnackOpen] = useState(false);
    const [snack, setSnack] = useState({
        severity: 'success',
        message: 'Usuario creado con éxito'
    });

    const dispatch = useDispatch();

    const [validForm, setValidForm] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        email: '',
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    }

    const resetPassword = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/forgot-password`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin"
            },
            body: JSON.stringify(values)
        });

        //console.log(response.status);
        if (response.status === 200) {
            setLoading(false);
            setSnack({
                severity: 'success',
                message: 'Se ha enviado un email a su casilla de correo'
            })
            setSnackOpen(true);
        }

        else if (response.status === 404) {
            setLoading(false);
            setSnack({
                severity: 'error',
                message: 'El email ingresado no existe'
            })
            setSnackOpen(true);
        }

        else {
            setLoading(false);
            setSnack({
                severity: 'error',
                message: 'Ha ocurrido un error'
            })
            setSnackOpen(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        resetPassword();
    }

    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <SnackbarAlert message={snack.message} severity={snack.severity} open={snackOpen} setOpen={setSnackOpen} />
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 col-12">
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <h1>Olvidé mi contraseña</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <form onSubmit={submitForm}>
                                            <div className="mb-3">
                                                <TextField required type="email" fullWidth label="Email" variant="filled" value={values?.email} onChange={handleChange('email')} />
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn w-100 btn-primary" type="submit">Enviar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </div>
    );
};

export default ForgotPassword;