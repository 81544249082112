import React, {useEffect} from 'react';
import {BiSearch} from "react-icons/bi";
import {VscThreeBars} from "react-icons/vsc";
import {IoClose} from "react-icons/io5";
import {Link} from "react-router-dom";
import '../styles/HomePageUsersDark.css';
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {IoMdArrowDropdown} from "react-icons/io";
import {Divider} from "@mui/material";

const NavbarHomePage = (props) => {

    const [open, setOpen] = React.useState(false);
    const [searchActive, setSearchActive] = React.useState(false);
    const [token, setToken] = React.useState("");

    const user = useSelector(selectUser);
    const handleClick = () => {
        setOpen(!open);
    }

    const handleSearch = () => {
        setSearchActive(!searchActive);
    }

    const changeBackground = () => {
        if (window.scrollY > 0) {
            document.getElementById("navbar").style.position = "fixed";
            document.getElementById("dropdownDesktop").style.position = "fixed";
        }
        else {
            document.getElementById("navbar").style.position = "absolute";
            document.getElementById("dropdownDesktop").style.position = "absolute";
        }
    }

    useEffect(() => {
        if (props.page !== "home") {
            document.getElementById("navbar").style.backgroundColor = "#0e1111";
        }
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changeBackground);
        window.addEventListener("click", clickOutside);

        return () => {
            window.removeEventListener("scroll", changeBackground);
        }
    })

    const clickOutside = (e) => {
        if (open) {
            if (!e.target.classList.contains("openDiv") && !e.target.classList.contains("openButton") && !e.target.classList.contains("openIcon") && e.target.tagName !== "path") {
                setOpen(false);
            }
        }
    }

    const closeSession = () => {
        localStorage.removeItem("token");
        window.location.reload();
    }



    useEffect(() => {
        if (searchActive) {
            setTimeout(() => {
                document.getElementById("searchDiv").style.width = "100%";
                document.getElementById("searchDiv").style.transition = "width 0.5s";
                document.getElementById("mobileInputSearch").focus();

            }, 0);
            try {
                document.getElementById("auxScroll").scrollIntoView({behavior: 'smooth'});
            } catch (e) {

            }
        }
        else {
            document.getElementById("searchDiv").style.width = "20%";
            document.getElementById("searchDiv").style.transition = "width 0.5s";
        }

    }, [searchActive]);

    const handleSearchChange = (e) => {
        document.getElementById("auxScroll").scrollIntoView({ behavior: 'smooth' });
        props.setSearching(e.target.value);
    }

    useEffect(() => {
        if (open) {
            document.getElementById("navbar").style.backgroundColor = "#0e1111";
        }
        else if (props.page === "home") {
            document.getElementById("navbar").style.backgroundColor = "transparent";
        }
        else {
            document.getElementById("navbar").style.backgroundColor = "#0e1111";
        }
    }, [open, props.page]);

    useEffect(() => {
        setToken(user.token);
    }, [user.token]);

    return (
        <>
        <nav id="navbar" style={{top: "0", left: "0", right:"0", backgroundColor: "#0e1111", zIndex: 100, position: "absolute"}}>
            <div>
                <div className="container">
                    <div className="row align-items-center justify-content-between" style={{height: "60px"}}>
                        <div className="col">
                            <div id="rowNavMobile" className={`row ${searchActive ? "d-none d-md-flex" : ""}`}>
                                <div className="col-4 d-md-none">
                                    {/*
                                        props.page === "home" ? (
                                            <button onClick={handleSearch} style={{padding: "5px 15px", backgroundColor: "transparent", border: 0}} type="button"><BiSearch size={20} color="whitesmoke" /></button>
                                        ) : null
                                    */}
                                </div>
                                <div className="col-md-auto col-4 text-center">
                                    <Link to={"/"}><img src="/logo512.png" alt="Logo Flashpass" border="0" height={40}/></Link>
                                </div>
                                <div className="col-4 d-md-none text-end openButton">
                                    <button className="openButton" onClick={handleClick} style={{padding: "5px 15px", backgroundColor: "transparent", border: 0}} type="button"><VscThreeBars className="openIcon" size={20} color="whitesmoke" /></button>
                                </div>
                                <div className="col d-none d-md-block">
                                    {/*props.page === "home" ? (
                                    <div className="input-group searchContainer">
                                        <div className="input-group-apend me-0">
                                            <button style={{padding: "5px 15px", backgroundColor: "transparent", border: 0}} type="button"><BiSearch size={20} color="whitesmoke" /></button>
                                        </div>
                                        <input type="text" className="inputSearch form-control ps-0" placeholder="Buscar eventos" aria-label="Buscar eventos" aria-describedby="basic-addon2" onChange={handleSearchChange} value={props.searching} />
                                    </div>
                                    ) : null*/}
                                </div>
                            </div>
                            <div className={`row searchbarMobile ${searchActive ? 'active d-md-none' : 'd-none'}`}>
                                <div className="col">
                                    <div id="searchDiv" className="input-group searchContainer" style={{width: "20%"}}>
                                        <div className="input-group-apend me-0">
                                            <button onClick={handleSearch} style={{padding: "5px 15px", backgroundColor: "transparent", border: 0}} type="button"><BiSearch size={20} color="whitesmoke" /></button>
                                        </div>
                                        <input type="text" id="mobileInputSearch" className="inputSearch form-control ps-0" placeholder="Buscar eventos" aria-label="Buscar eventos" aria-describedby="basic-addon2" onChange={handleSearchChange} value={props.searching} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto d-none d-md-block">
                            <div className="row">
                                {/*
                                <div className="col-auto">
                                    <Link to={"/login"} className="btn border-0 shadow-0 text-white me-2">Publicar un evento</Link>
                                </div>
                                */}
                                <div className="col-auto">
                                    {token === "" ? (
                                    <Link to={"/login"} className="btn border-0 shadow-0 text-white">Ingresar</Link>
                                    ) : (
                                    <button id={"dropdownDesktopButton"} onClick={handleClick} className="btn border-0 shadow-0 text-white align-items-center d-flex openButton">Mi Cuenta<IoMdArrowDropdown /></button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    <div id={"dropdownDesktop"} className={`navbarDesktop openDiv text-start d-none d-md-flex ${open ? "active" : ""}`}>
        {token === "" ? (
            <Link onClick={handleClick} to={'/login'} className="d-md-block d-none mt-3">Ingresar</Link>
        ) : (
            <>
                <Link onClick={handleClick} to={'/tickets'} className="d-md-block d-none">Mis Tickets</Link>
                <Link onClick={handleClick} to={'/my-profile'} className="d-md-block d-none">Perfil</Link>
                {
                    //<a onClick={handleClick} href={"https://flashpass.com.ar/mis_eventos"} target={"_blank"} className="d-md-block d-none">Panel de control organizador</a>}
                }
                <Link onClick={handleClick} to={'/organizations/dashboard'} className="d-md-block d-none">Panel de control organizador</Link>
                <Divider className="mb-1 mt-1" />
                <button onClick={closeSession} className="d-md-block d-none text-start text-danger">Cerrar sesión</button>
            </>
        )}
    </div>

    <div className={`navbarMobile openDiv d-flex d-md-none ${open ? "active" : ""}`}>
        {/*<Link onClick={handleClick} to={'/'} className="d-md-none d-block">Publicar un evento</Link>*/}
        {token === "" ? (
            <Link onClick={handleClick} to={'/login'} className="d-md-none d-block mt-3">Ingresar</Link>
        ) : (
            <>
                <Link onClick={handleClick} to={'/tickets'} className="d-md-none d-block">Mis Tickets</Link>
                <Link onClick={handleClick} to={'/my-profile'} className="d-md-none d-block">Perfil</Link>
                {
                    //<a onClick={handleClick} href={"https://flashpass.com.ar/mis_eventos"} target={"_blank"} className="d-md-none d-block">Panel de control organizador</a>
                }
                <Link onClick={handleClick} to={'/organizations/dashboard'} className="d-md-none d-block">Panel de control organizador</Link>
                <Divider style={{backgroundColor: "white", width: "100%"}} />
                <button onClick={closeSession} className="d-md-none d-block text-danger buttonLink" style={{border: "none", backgroundColor: "transparent"}}>Cerrar sesión</button>
            </>
        )}
        <div className="d-md-none d-block" style={{position: "absolute", right: 0, top: 0, padding: "1rem"}}>
            <button style={{backgroundColor: "transparent", border: 0}} onClick={handleClick} type="button">
                <IoClose size={30} color="whitesmoke" />
            </button>
        </div>
    </div>
    </>
    );
};

export default NavbarHomePage;