import React, {useEffect} from 'react';
import NavbarHomePage from "./NavbarHomePage";

const faqData = [
    {
        question: "¿Dónde encuentro mis entradas?",
        answer: "Podrás encontrar tus entradas tanto en los emails que te enviamos como en nuestra página web: https://www.flashpass.com.ar/ dentro de “Mi Cuenta”, “Mis Tickets”. Para ver tus entradas desde la página, tendrás que iniciar sesión o registrarte con el mismo email que utilizaste para comprar las entradas. Por motivos de seguridad, verás que el código QR de tus entradas va a estar bloqueado hasta 3 horas antes del evento. Llegado ese momento, te enviaremos un email de recordatorio, con la entrada en PDF con el código QR desbloqueado."
    },
    {
        question: "¿Cómo pagar mis entradas?",
        answer: "Podes pagar con cualquier medio de pago electrónico que acepte MercadoPago. Todas las tarjetas Visa, MasterCard, American Express, etc."
    },
    {
        question: "¿Cómo ingreso al evento?",
        answer: "Para ingresar al evento, debes acercarte a los puntos de acceso y mostrar el código QR de tus entradas directamente desde tu celular.\n" +
            "Podrás encontrar tus entradas tanto en los emails que te enviamos como en nuestra página web: https://www.flashpass.com.ar/ dentro de “Mi Cuenta”, “Mis Tickets”. \n" +
            "Las entradas son de un solo uso, por lo que una vez que se escanee el código QR, quedarán invalidadas."
    },
    {
        question: "¿Cómo puedo contactarme con ustedes?",
        answer: "Podes contactarte con nosotros enviando un email a info@flashpass.com.ar y te responderemos a la brevedad para resolver tu consulta."
    },
];

const faqMentaData = [
    {
        "question": "¿Cómo funciona la venta de tickets en el Mercado Secundario?",
        "answer": "Si tienes tickets para un evento al que ya no puedes asistir, puedes venderlos en nuestro mercado secundario.\n\nSolo necesitas ir a la sección “Mis Tickets” y seleccionar “Vender”. Ahí tendrás que elegir dónde quieres recibir el pago (CBU/CVU o alias) y el precio al que lo quieres vender.\n\nUna vez que confirmes tus datos, el ticket estará automáticamente en venta. Podrás esperar a que alguien decida comprarlos de manera anónima o compartir el link de tu publicación con amigos para aumentar su exposición."
    },
    {
        "question": "¿Cómo sé si mi ticket se puede revender?",
        "answer": "En la sección “Mis Tickets”, si en un ticket aparece el botón de “Vender” significa que el ticket es elegible para la venta."
    },
    {
        "question": "¿Cómo funciona el link de mi publicación?",
        "answer": "Cada vez que publicas un ticket en nuestra plataforma, automáticamente pasa a estar disponible para que cualquiera lo pueda comprar en el mercado secundario.\n\nSin embargo, puedes difundir tu publicación por tu cuenta y al hacer click en el link, las personas verán solamente tu publicación."
    },
    {
        "question": "¿Es gratis publicar a la venta un ticket?",
        "answer": "Poner a vender tickets siempre es gratis. Sin embargo, se cobran cargos por el servicio ofrecido, que se suman al precio del ticket que paga el comprador.\n\nDurante el proceso de publicación de tu ticket podrás ver cuánto recibirás por la venta y cuánto se añade al precio del ticket en concepto de cargos por servicio."
    },
    {
        "question": "¿Cómo edito mi ticket en venta?",
        "answer": "Una vez que hayas publicado tu ticket, podrás sacarlo de la venta en cualquier momento. Además podrás modificar el precio y tus datos de pago si aún no se ha vendido o faltan mas de 3hs para el evento. Dirígete a la sección de “Mis Tickets\", y presiona “Editar”."
    },
    {
        "question": "¿Cómo me entero si vendí mi ticket?",
        "answer": "Te avisaremos vía email sobre tu venta y todo lo que necesitas saber. También entrando a “Mis Tickets” vas a poder ver el ticket como “Vendido”."
    },
    {
        "question": "¿Cuándo me pagan?",
        "answer": "Procesaremos el pago por tu venta, 72hs hábiles después de la finalización del evento. El tiempo que tarde en ver el dinero en su cuenta, dependerá del banco o billetera virtual."
    },
    {
        "question": "¿Cómo cancelo la venta de mi ticket?",
        "answer": "Dirígete a la sección de “Mis Tickets”, encuentra el ticket que quieres sacar de la venta y presiona “Retirar”."
    },
    {
        "question": "¿Puedo cancelar la venta después de que se vendió?",
        "answer": "No, una vez efectuada la compra no se puede cancelar la operación."
    },
    {
        "question": "¿Por qué mis tickets ya no están a la venta?",
        "answer": "Para garantizar un proceso seguro, la reventa estará habilitada hasta 3 horas antes del comienzo del evento. Llegado ese momento, todos los tickets dejan de estar en venta y quedan disponibles para uso personal. Adicionalmente tanto el organizador del evento como FlashPass pueden deshabilitar el mercado secundario en cualquier momento."
    },
    {
        "question": "¿Qué sucede si mi ticket no se vende?",
        "answer": "Lo podrás usar para disfrutar del evento o lo puedes compartir con un familiar o una amiga o amigo 🙂"
    },
    {
        "question": "¿Tengo que tratar con el comprador al vender mis tickets?",
        "answer": "Nunca. Nosotros nos encargamos del pago y cualquier operación involucrada, por lo que los compradores y vendedores nunca tienen que interactuar entre sí."
    }
];

const mentaFaqBuyData = [
    {
        "question": "¿Cómo puedo comprar un ticket en el Mercado Secundario?",
        "answer": "El proceso es muy similar a la compra en el mercado primario. Solo necesitas buscar el evento deseado, hacer click en “Mercado Secundario”, seleccionar las entradas deseadas y terminar con el proceso de compra."
    },
    {
        "question": "¿El ticket es oficial?",
        "answer": "Todos los tickets del mercado secundario son oficiales y seguros. No existen los tickets falsificados, y nadie más que vos va a tener acceso al QR para entrar al evento."
    },
    {
        "question": "¿Qué métodos de pago aceptan?",
        "answer": "Para el mercado secundario aceptamos todas las tarjetas de crédito, débito o prepaga de Visa, MasterCard o AMEX."
    },
    {
        "question": "Mi pago de un ticket en el Mercado Secundario se está rechazando ¿Qué puedo hacer?",
        "answer": "Si tu pago no ha sido aceptado, te aconsejamos revisar el siguiente listado para ayudar a que el proceso de pago se realice satisfactoriamente:\n\nAsegúrate de que has introducido el código de seguridad correcto (también llamado CVV o CVV2). En las tarjetas Visa, Mastercard, son los 3 últimos números que aparecen en el dorso de tu tarjeta. En American Express, es el número de cuatro cifras ubicado en la parte delantera de tu tarjeta, a la derecha.\n\nAsegúrate de que los datos de la tarjeta de débito/crédito/prepaga que has introducido corresponden a una tarjeta válida, con fecha de caducidad en el futuro y fondos suficientes para cubrir la compra.\n\nPonte en contacto con la compañía de tu tarjeta, con tu banco o la empresa que te otorgó la tarjeta para comprobar la autorización de pago.\n\nPrueba con una tarjeta internacional de Visa, Mastercard o American Express, si todavía no lo has hecho."
    },
    {
        "question": "¿Cómo puedo ver el cargo en el resumen de mi tarjeta?",
        "answer": "Todas las compras del mercado secundario de tickets, aparecerán en tu tarjeta con el nombre de “FlashPass”. Si no reconoces alguno de ellos, ponte en contacto con nuestro servicio de atención al cliente: info@flashpass.com.ar"
    },
    {
        "question": "No me llegó un mail de confirmación cuando compré un ticket en el Mercado Secundario ¿Qué puedo hacer?",
        "answer": "Revisa tu carpeta de spam, correos no deseados o cualquier otra carpeta que tengas en tu bandeja de entrada. Si no está, asegúrate de que hayas escrito bien tu email y que el ticket te aparezca en “Mis Tickets”. Si no te aparece es que la compra no se realizó con éxito."
    },
    {
        "question": "¿Me puedo arrepentir de la compra en el Mercado Secundario?",
        "answer": "No, una vez efectuada la compra no se otorgan devoluciones. Te recomendamos que los vuelvas a vender aquí: [link a sell tickets]"
    },
    ]

const Faq = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className="eventPreviewDarkPage" style={{backgroundColor: "#1a1a1a", color: "white"}}>
            <NavbarHomePage page={"event"} />
            <div className="container" style={{backgroundColor: "#1a1a1a", marginTop: "60px"}}>
                <div className="row">
                    <div className="col-12">
                        <h1>Preguntas Frecuentes</h1>
                    </div>
                </div>

                <div className="row">
                    {faqData.map((faq, index) => (
                        <div className="col-lg-6 mt-3" key={index}>
                            <div className="card spCard" style={{
                                height: "100%",
                            }}>
                                <div className="card-body">
                                    <h5 className="card-title">{faq.question}</h5>
                                    <p className="card-text">{faq.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <h3>Vender tickets a través de nuestro Mercado Secundario:</h3>
                    </div>
                </div>

                <div className="row">
                    {faqMentaData.map((faq, index) => (
                        <div className="col-lg-6 mt-3" key={index}>
                            <div className="card spCard" style={{
                                height: "100%",
                            }}>
                                <div className="card-body">
                                    <h5 className="card-title">{faq.question}</h5>
                                    <p className="card-text">{faq.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row mt-4">
                    <div className="col-12">
                        <h3>Comprar tickets a través de nuestro Mercado Secundario:</h3>
                    </div>
                </div>

                <div className="row">
                    {mentaFaqBuyData.map((faq, index) => (
                        <div className="col-lg-6 mt-3" key={index}>
                            <div className="card spCard" style={{
                                height: "100%",
                            }}>
                                <div className="card-body">
                                    <h5 className="card-title">{faq.question}</h5>
                                    <p className="card-text">{faq.answer}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default Faq;