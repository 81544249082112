import { createSlice } from "@reduxjs/toolkit";

export const ticketsSlice = createSlice({
    name: "tickets",
    initialState: {
        tickets: {
            items: [],
        }
    },
    reducers: {
        addTicket: (state, action) => {
            state.tickets.items.push(action.payload.item);
        },
        substractTicket: (state, action) => {
            for (let i = 0; i < state.carro.items.length; i++) {
                if (state.tickets.items[i].id === action.payload.item.id) {
                    state.tickets.items.splice(i, 1);
                    break;
                }
            }
        },
        removeTicket: (state, action) => {
            state.tickets.items = state.carro.items.filter(item => item.id !== action.payload.item.id);
        }
    }
});

export const { addTicket, substractTicket, removeTicket } = ticketsSlice.actions;

export const selectTicket = (state) => state.tickets.tickets;

export default ticketsSlice.reducer;