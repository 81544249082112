import React from 'react';
import {Link} from "react-router-dom";
import {number_format} from "./functions/numberFormat";
import {Skeleton} from "@mui/lab";

const ItemsContainer = (props) => {
    // function to make the matching text bold
    const makeBold = (text, search) => {
        try {
            const clearInput = document.querySelector('.clear-input');

            if (clearInput.classList.contains('active') && !search) clearInput.classList.remove('active');

            if (!search) return text;

            clearInput.classList.add('active');
            const regex = new RegExp(`(${search})`, 'gi');
            return text.split(regex).map((part, i) =>
                regex.test(part) ? <b key={i}>{part}</b> : part
            );
        } catch (error) {

        }
    }

    return (
        <div>
            {props.item ? (
            <Link to={`./item/${props.item.id}`} style={{textDecoration: 'none', color: "black"}}>
                <div className="row align-items-start">
                    {props.item.image ? (
                        <>
                            <div className="col-8">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="items-title">{makeBold(props.item.title, props.search)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="items-description">{props.item.description}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="item-price">
                                            {props.item.price === 0 ? "Gratuito" :
                                                <span>${number_format(props.item.price, 2, ',', '.')}</span>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <img className="items-icon" src={props.item.image} alt="item" />
                            </div>
                        </>
                    ) : (
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <p className="items-title">{makeBold(props.item.title, props.search)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="items-description">{props.item.description}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p className="item-price">
                                        {props.item.price === 0 ? "Gratuito" :
                                            <span>${number_format(props.item.price, 2, ',', '.')}</span>}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Link>
            ) : (
                <div className="row align-items-start">
                    <>
                        <div className="col-8">
                            <div className="row">
                                <div className="col-12">
                                    <Skeleton variant="text" width="100%" height={30} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Skeleton variant="text" width="100%" height={20} />
                                    <Skeleton variant="text" width="100%" height={20} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Skeleton variant="text" width="100%" height={20} />
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <Skeleton variant="rectangular" width="100%" height={100} />
                        </div>
                    </>
                </div>
            )}
        </div>
    );
};

export default ItemsContainer;