import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { FreeMode } from "swiper";
import { Button, Skeleton } from "@mui/material";

const CategoriesSwiper = (props) => {
    const swiperRef = useRef(null);  // Step 2: Create a reference for Swiper

    const handleCategoryClick = (id) => {
        props.setActiveCategory(id);
        const element = document.getElementById(`items-categ-container-${id}`);

        window.scrollTo({
            top: element.offsetTop - 105,
            behavior: 'smooth'
        });

        // Step 3: Use the Swiper's slideTo method to focus on the corresponding slide
        if (swiperRef.current) {
            const slideIndex = props.categories.findIndex(category => category.id === id);
            swiperRef.current.swiper.slideTo(slideIndex);
        }
    };

    // useEffect to handle when activeCategory prop changes
    useEffect(() => {
        if (swiperRef.current) {
            const slideIndex = props.categories.findIndex(category => category.id === props.activeCategory);
            swiperRef.current.swiper.slideTo(slideIndex);
        }
    }, [props.activeCategory, props.categories]);

    return (
        <Swiper
            ref={swiperRef}  // Step 4: Connect Swiper instance to our React component
            slidesPerView={'auto'}
            spaceBetween={25}
            style={{ margin: 0, padding: 0 }}
            freeMode={true}
            modules={[FreeMode]}
        >
            {props.categories.length > 0 ? props.categories.map((category, i) => (
                <SwiperSlide key={i} style={{ width: 'auto' }}>
                    <div className={`nav-categories-element ${props.activeCategory === category.id ? 'active' : null}`} id={`nav-categ-${category.id}`}>
                        <button onClick={() => handleCategoryClick(category.id)} className="nav-categories-element-button" style={{backgroundColor: "transparent", border: "none"}}>
                            <span className="nav-categories-element-text">{category.title}</span>
                        </button>
                    </div>
                </SwiperSlide>
            )) : Array.from(Array(5).keys()).map((i) => (
                <SwiperSlide key={i} style={{ width: 'auto' }}>
                    <div className={`nav-categories-element`}>
                        <Skeleton variant="text" width={80} height={30} style={{borderRadius: "10px"}} />
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default CategoriesSwiper;