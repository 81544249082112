import React, {useEffect, useState} from 'react';
import MenuHeader from "./MenuHeader";
import NavCategories from "./NavCategories";
import {Container} from "react-bootstrap";
import CategoriesContainer from "./CategoriesContainer";
import CarroButton from "./CarroButton";
import {motion} from "framer-motion";
import {useLocation, useNavigate} from "react-router";
import {CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";

const MenuOrder = (props) => {

    const location = useLocation();
    const [animation, setAnimation] = useState(false);
    const navigate = useNavigate();

    const [order, setOrder] = useState([]);

    const query = new URLSearchParams(location.search);
    const mp_status = query.get('status');
    const [isLoaded, setIsLoaded] = useState(false);

    const token = window.localStorage.getItem('token');
    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders?order_id=${props.order}&action=view`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin",
                "Authorization": `Bearer ${token}`
            }
        });
        const response = await request.json();
        setOrder(response);
        setIsLoaded(true);
    }

    const cancelOrder = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                "order_id": props.order
            })
        });

        const response = await request.json();

        if (response.status === "success") {
            navigate("/my-account");
        }
    }

    // add new state for circle color
    const [circleColor, setCircleColor] = useState('');

    useEffect(() => {
        if (mp_status !== 'approved') {
            setAnimation(false);
            return;
        }

        setAnimation(true);

        // simulate the animations
        setTimeout(() => {
            setCircleColor('green'); // replace with your color change logic
        }, 1000);

        setTimeout(() => {
            setCircleColor('');
            setAnimation(false);
        }, 3000); // replace with your animation time

    }, [order, mp_status, navigate, location.pathname]);


    useEffect(() => {
        document.querySelector("meta[name=theme-color]").setAttribute("content", "#303139");
        const requests = setTimeout(() => {
            fetchData();
        }, 10);
        return () => clearTimeout(requests);
    }, []); // empty dependencies array ensures this runs only once at component mount

    return (
        <>
            <div className="menu-container" id="menu-container">
                <div className="menu-header" style={{overflow: "hidden"}}>
                    <MenuHeader id={order?.menu_id} returnButton={mp_status === "approved" ? `/menu/${order?.menu_url}/${order?.event_url ? order?.event_url : ""}` : null} />
                </div>
                <div className="menu-body" id="menu-body" style={{overflowY: "scroll", overflowX: "hidden"}}>
                    {animation ? (
                        <div className="animation-container">
                            <div className="tick-container">
                                <img src="/assets/images/checkmark-128.png" alt="tick" className="tick"/>
                                <div className="circle"></div>
                            </div>
                        </div>
                    ) : null}
                    {!animation ? (
                        <>
                            {!isLoaded ? (
                                <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                                    <CircularProgress color="secondary" size={100} />
                                </div>
                            ) : (
                                <div>
                                    {order.order_status === 'processing' || order.order_status === 'delivered' ? (
                                        <Container>
                                            {mp_status === 'approved' ? (
                                                <>
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <h3>Tu compra fue realizada con éxito.</h3>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-12">
                                                            <p>Podés ver el estado de tu pedido en la sección <a href="/orders">Mis Pedidos</a></p>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p>El código de tu pedido es: <span className="font-weight-bold">{order.id}</span></p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="order-detail">
                                                        <div className="order-detail-title">
                                                            <p>Items del pedido:</p>
                                                        </div>
                                                        <div className="order-detail-body">
                                                            {order.products ? order.products.map((product, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="order-detail-body-item" key={index}>
                                                                            <p className="order-detail-body-item-p mb-0">{product.quantity}x {product.title}</p>
                                                                        </div>
                                                                        {product.variants ? product.variants.map((variant, index) => {
                                                                            return (
                                                                                <p className="order-detail-body-item-p ms-3 mt-1" style={{fontSize: "0.85rem"}}
                                                                                   key={index}>- {variant.category_name}: {variant.quantity > 1 ? variant.quantity + "x" : ""} {variant.name}</p>
                                                                            )
                                                                        }) : null
                                                                        }
                                                                    </>
                                                                )
                                                            }) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {order?.order_status === 'processing' ? (
                                            <>
                                                <div className="row mt-3 text-center justify-content-center">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <img src={`https://flashpass.com.ar/bw_codes/${props.order}.png`} alt={"order-" + props.order} className="img-fluid order-qr-container" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-12">
                                                            <p className="order-qr-subtitle">Mostrá este codigo QR en el mostrador para que puedan entregarte tu pedido.</p>
                                                            <p className="order-qr-subtitle mt-1" style={{fontSize: "0.85rem"}}>También te enviamos una copia a tu correo electrónico ;)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {mp_status === 'approved' ? (
                                                    <>
                                                        <div className="row mt-5">
                                                            <div className="col-12">
                                                                <p>¡Gracias por tu compra!</p>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <p>El equipo de FlashPass</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : <div className="mt-4"></div>}
                                            </>
                                            ) : <div className="mt-4"></div>}
                                            <div className="row">
                                                <div className="col-12">
                                                    {order?.order_total <= 0 ? (
                                                        <button style={{color: "red", border: "none", padding: 0, backgroundColor: "transparent"}} onClick={cancelOrder}>Cancelar pedido</button>
                                                    ) : null}
                                                    <p className="mt-3"><Link to={`/menu/${order?.menu_url}/${order?.event_url ? order?.event_url : ""}`}>Volver al menú</Link></p>
                                                </div>
                                            </div>
                                        </Container>
                                    ) : (
                                        <Container>
                                            <div className="row mt-2">
                                                <div className="col-12">
                                                    <h3>El pago de tu órden aún no fue confirmado.</h3>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12">
                                                    <p>Podés ver el estado de tu pedido en la sección <a href="/orders">Mis Pedidos</a></p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>El código de tu pedido es: <span className="font-weight-bold">{order.id}</span></p>
                                                </div>
                                            </div>
                                        </Container>
                                    )}
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default MenuOrder;