import React, {useEffect, useState} from 'react';
import EventsSwiper from "../components/EventsSwiper";
import EventsSwiperHomePage from "../components/EventsSwiperHomePage";
import NavbarHomePage from "../components/NavbarHomePage";
import '../styles/HomePageUsersDark.css';
import EventCard from "../components/EventCard";
import {CircularProgress, ThemeProvider} from "@mui/material";
import Footer from "../components/Footer";

const HomePageUsersDark = (props) => {

    const [activeEvents, setActiveEvents] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [pastEvents, setPastEvents] = React.useState([]);
    const [searching, setSearching] = React.useState("");

    const [search, setSearch] = useState("");

    const changeBackground = () => {
        if (window.scrollY > 0) {
            document.getElementById("navbar").style.position = "fixed";
        }
        else {
            document.getElementById("navbar").style.position = "static";
            document.getElementById("navbar").style.backgroundColor = "transparent";
        }

        if (window.scrollY > Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) / 3) {
            document.getElementById("textLema").classList.add("opacity-0");
        }
        else {
            document.getElementById("textLema").classList.remove("opacity-0");
        }
        if (window.scrollY) {
            document.getElementById("goToEvents").classList.add("opacity-0");
        }
        else {
            document.getElementById("goToEvents").classList.remove("opacity-0");
        }
        if (window.scrollY > Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 60 || document.getElementById("dropdownDesktop").classList.contains("active")) {
            document.getElementById("navbar").style.transition = "background-color 0.15s";
            document.getElementById("navbar").style.backgroundColor = "#0e1111";
        }
        else {
            document.getElementById("navbar").style.backgroundColor = "transparent";
        }
    }

    const scrollToEvents = () => {
        document.getElementById("auxScroll").scrollIntoView({behavior: 'smooth'})
    }

    useEffect(() => {
        // adding the event when scroll change Logo
        window.addEventListener("scroll", changeBackground);

        if (window.scrollY > 0 || searching !== "") {
            document.getElementById("auxScroll").scrollIntoView({ behavior: 'smooth' })
        }

        if (window.scrollY === 0) {
            document.getElementById("navbar").style.backgroundColor = "transparent";
        }

        return () => {
            window.removeEventListener("scroll", changeBackground);
        }
    });

    const [isLoaded, setIsLoaded] = React.useState(false);

    const fetchData = async () => {
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/events-home-page`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();

        if (data.status === "success") {
            setActiveEvents(data.data.disponible);
            setEvents(data.data.disponible);
            setPastEvents(data.data.entradas);
            setIsLoaded(true);
        }

    }

    const fetchDataSearch = async () => {
        setIsLoaded(false);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/events-home-page?search=${searching}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        });
        const data = await response.json();

        if (data.status === "success") {
            setActiveEvents(data.data.disponible);
            setEvents(data.data.disponible);
            setPastEvents([]);
            setIsLoaded(true);
        }

    }

    useEffect(() => {
        if (searching !== "") {
        const requests = setTimeout(() => {
            fetchDataSearch();
        }, 300);
        return () => clearTimeout(requests);
        }
        else {
            const requests = setTimeout(() => {
                fetchData();
            }, 300);
            return () => clearTimeout(requests);
        }
    }, [searching]);


    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    return (
        <div className="containerDark">
            <ThemeProvider theme={props.theme}>
                <section>
                    <div className="row">
                        <div className="col-12">
                            <div className="fullPageContainer">
                                <NavbarHomePage searching={searching} setSearching={setSearching}  page={"home"} />
                                <div className="text-center" id="textLema" style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                                    <h1>FLASHPASS</h1>
                                    <h2>Preparate para tu próxima experiencia.</h2>
                                </div>
                                <div className="text-center mb-5 mb-md-0 pb-5 pb-md-0" style={{position: "absolute", bottom: "0", left: "50%", transform: "translate(-50%, 0)"}}>
                                    <button id="goToEvents" className="btn btn-outline-white mb-md-4 mb-5" onClick={() => {
                                        document.getElementById("auxScroll").scrollIntoView({ behavior: 'smooth' })
                                    }}>Ver eventos</button>
                                </div>
                                <div id="auxScroll" style={{position: "absolute", opacity: 0, bottom: "0", left: "50%", height: "59px", transform: "translate(-50%, 0)"}}></div>
                            </div>
                        </div>
                    </div>
                </section>


                <section id="section2">
                    {!isLoaded ? (
                        <div className="text-center" style={{padding: "20rem 0"}}>
                            <CircularProgress color="secondary" size={100} />
                        </div>
                    ): ""}
                    {isLoaded && (
                    <div className="row">
                        <div className="col-12">
                            {activeEvents.length === 0 ? (
                                <div className="text-center" style={{padding: "20rem 0"}}>
                                    <h3>No hay eventos que coincidan con tu búsqueda</h3>
                                </div>
                            ) : (
                                <>
                                    <div className="container">
                                        <div className="row mb-2">
                                            <div className="col-12 mt-4">
                                                <h3>Próximos Eventos</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <EventsSwiperHomePage theme={"dark"} events={activeEvents} />
                                    </div>
                                </>
                            )}

                            {!searching && (
                                <>
                                    <div className="container">
                                        <div className="row mb-2">
                                            <div className="col-12 mt-4">
                                                <h3>Eventos pasados</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <EventsSwiperHomePage disabled={true} theme={"dark"} events={pastEvents} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    )}
                </section>

                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default HomePageUsersDark;