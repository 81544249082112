import React from 'react';
import {Button, Container} from "react-bootstrap";
import CategoriesContainer from "./CategoriesContainer";

const Products = (props) => {

    return (
        <>
            <Container>
                {props.categories.length > 0 ? props.categories.map((category, index) => (
                    <CategoriesContainer data-index={category.id} search={props.search} category={category} key={index} />
                )) : (
                    <CategoriesContainer category={{
                        id: 0,
                        products: [],
                    }} search={props.search} />
                )}
            </Container>
            {props.items.items.length > 0 && <div style={{marginTop: "70px"}}></div>}
        </>
    );

};

export default Products;