import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {BiArrowBack} from "react-icons/bi";
import {collapseSearchBar, selectExpandedSearchBar} from "../redux/carroSlice";
import {useDispatch, useSelector} from "react-redux";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {FaRegUserCircle} from "react-icons/fa";
import {selectUser} from "../redux/userSlice";
import {useLocation} from "react-router";
import {Skeleton} from "@mui/lab";
import {selectMenu, selectMenuIsLoaded, setMenu} from "../redux/menuSlice";
import {splitNumber} from "./functions/numberFormat";
import MenuAuthentication from "./MenuAuthentication";

const MenuHeader = (props) => {

    const navigate = useNavigate();
    const expanded = useSelector(selectExpandedSearchBar);

    // Inside your component
    const menu = useSelector(selectMenu);
    const menuIsLoaded = useSelector(selectMenuIsLoaded);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const location = useLocation();
    const navigation = useNavigate();

    const [userBalance, setUserBalance] = useState(null);
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    }
    const handleCloseConfirm = () => setOpenConfirm(false);

    const fetchData = async (id) => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu?menu_id=${id}&event=${props.event}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin",
                "Authorization": `Bearer ${user.token}`,
            }
        });

        const response = await request.json();
        if (response.status === "success") {
            dispatch(setMenu(response.menu));
        }
    }

    const fetchUser = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/profile-users?menu_id=${props.id}&event=${props.event}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            }
        });

        const response = await request.json();
        if (response?.status === "success") {
            setUserBalance(response.balance);
        }
    }

    function handleBackButtonClick() {

        const searchContainer = document.querySelector('.search-container');
        const searchIcon = document.querySelector('.search-icon');
        const searchInput = document.querySelector('.search-input');

        if (props.returnButton) {
            navigate(props.returnButton);
            return;
        }

        if (expanded) {
            try {
                searchIcon.classList.remove('active');
                searchInput.classList.remove('active');
                searchContainer.classList.remove('expanded');
                dispatch(collapseSearchBar());
                props.setSearch('');
            } catch (error) {
                if (window.location.pathname.split("/").pop() === "carro") {
                    //remove last part of url
                    const pathArray = window.location.pathname.split("/");
                    pathArray.pop();
                    const newPathname = pathArray.join("/");
                    navigate(newPathname);
                } else {
                    if (window.history.length > 1) navigate(-1);
                }
            }
        }
        else {
            if (window.location.pathname.split("/").pop() === "carro") {
                //remove last part of url
                const pathArray = window.location.pathname.split("/");
                pathArray.pop();
                const newPathname = pathArray.join("/");
                navigate(newPathname);
            } else {
                if (window.history.length > 1) navigate(-1);
            }
        }
    }

    const menuCatSearchActive = () => {
        const pathArray = window.location.pathname.split("/");
        const secondSegment = pathArray[1];

        // Check if the second segment is "menu"
        if (secondSegment !== "menu") {
            return false;
        }

        // Check if any segment contains "carro" or "item"
        for (const segment of pathArray) {
            if (segment.includes("carro") || segment.includes("item")) {
                return false;
            }
        }

        return true;
    };


    useEffect(() => {
        //if menu is not loaded in redux, fetch it
        const requests = setTimeout(() => {
            //fetch data with the id from the url after /menu/
            const pathArray = window.location.pathname.split("/");
            let id;
            if (props.id && !menuIsLoaded) {
                fetchData(props.id);
            } else {
                id = pathArray[2];
            }

            // Only fetch data if menu is not loaded and menu id doesn't match the id from the URL
            if (!menuIsLoaded && (!menu || menu.id !== id) && pathArray.length !== 3 && pathArray[1] === "menu") {
                fetchData(id);
            }
        }, 300);

        return () => clearTimeout(requests);
    }, [menu, menuIsLoaded, fetchData]); // add menuIsLoaded and fetchData to the dependencies

    useEffect(() => {
        if (user.token) {
            fetchUser();

            const interval = setInterval(fetchUser, 15000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(interval);
        }
    }, [user]);


    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="menu-title-container" style={{backgroundColor: menu?.header_color}}>
                        {!menuCatSearchActive.call() || expanded ? (
                            <button id={"menu-go-back-btn"} onClick={handleBackButtonClick} style={{backgroundColor: "transparent", border: "none", color: "white"}}>
                                <BiArrowBack size={25} />
                            </button>
                        ) : (
                            <AiOutlineInfoCircle size={25} />
                        )}
                        {menu?.name ? (
                            userBalance?.balance ? (
                                <div className={"menu-header-balance-container text-center"}>
                                    <p className="menu-title-balance text-center mt-0 mb-0">{menu?.name}</p>
                                    <div className="menu-balance">
                                        <p className={"mb-0 mt-0"} style={{fontSize: "1rem", fontWeight: "bold"}}>
                                            Saldo: $ {splitNumber(userBalance?.balance).integer}
                                            <sup style={{fontSize: "0.4rem", marginLeft: "3px", top: "-7px", position: "relative"}}>
                                                {splitNumber(userBalance?.balance).decimal}
                                            </sup>
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <p className="menu-title">{menu?.name}</p>
                            )) : (
                            <Skeleton variant="text" width={200} height={30} />
                        )}
                        {user.token ? (
                        <Link to={"/my-account"} style={{color: "white"}}>
                            <FaRegUserCircle size={25} />
                        </Link>
                        ) : (
                            <button id={"menu-login-btn"} onClick={handleOpenConfirm} style={{backgroundColor: "transparent", border: "none", color: "white"}}>
                                <FaRegUserCircle size={25} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <MenuAuthentication openConfirm={openConfirm} handleCloseConfirm={handleCloseConfirm} id={props.id} event={props.event} />
        </div>
    );
};

export default MenuHeader;