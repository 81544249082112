import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    menu: null,
    isLoaded: false,
    userType: null
};

export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setMenu: (state, action) => {
            state.menu = action.payload;
            state.isLoaded = true; // The menu is loaded
        },
        setLogin: (state, action) => {
            state.userType = action.payload;
        },
        resetMenu: state => {
            state.menu = null;
            state.isLoaded = false; // The menu is not loaded
        },
    },
});

// Action exports
export const { setMenu, resetMenu, setLogin } = menuSlice.actions;

// The selectors
export const selectMenu = state => state.menu.menu;
export const usertTypeSelected = state => state.menu.userType;
export const selectMenuIsLoaded = state => state.menu.isLoaded;

// The reducer
export default menuSlice.reducer;
