import React from 'react';
import './EventCard.css';
import {Link} from "react-router-dom";
import {color} from "chart.js/helpers";
import {useNavigate} from "react-router";

const EventCard = (props) => {

    const navigate = useNavigate();

    const style = () => {
        if (props.color === "dark") {
            return {
                color: "whitesmoke"
            };
        }
    }

    if (props.title) {
        return (
            <>
                {/*<div className="col-md-4 col-lg-3 mb-4">*/}
                <Link target={props.target} to={`/event/${props.url}`} style={{textDecoration: "none", color: "black"}}>
                    <div className="eventCard" style={style()}>
                        <div className="row">
                            <div className="col-12">
                                <div className="eventCardImg" style={{backgroundImage: `url(${props.image})`}}>
                                    <img className="img-fluid centeredImg" src={props.image} />
                                    <div className="eventCardImgText d-flex align-items-center justify-content-center">
                                        <h5 unselectable="on" style={{fontSize: "1rem"}}>Ver Evento</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <p className="m-0" style={{fontSize: "18px"}}><strong>{props.title}</strong></p>
                                <p className="m-0" style={{fontSize: "14px"}}>{props.location}</p>
                                <p className="m-0" style={{fontSize: "14px", opacity: "60%"}}>{props.date}</p>
                            </div>
                        </div>
                    </div>
                </Link>
                {/*</div>*/}
            </>
        )
    }
    return (
        <>
            {/*<div className="col-md-4 col-lg-3 mb-4">*/}
                <Link to={"/event/prueba-8/dark"} style={{textDecoration: "none", color: "black"}}>
                    <div className="eventCard" style={style()}>
                        <div className="row">
                            <div className="col-12">
                                <div className="eventCardImg">
                                    <img className="img-fluid" src={"https://static.tixr.com/static/images/external/img/c7f694c2-77d6-4779-b475-551f44daa6b9.jpg"} />
                                    <div className="eventCardImgText d-flex align-items-center justify-content-center">
                                        <h5 unselectable="on" style={{fontSize: "1rem"}}>Ver Evento</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12">
                                <p className="m-0" style={{fontSize: "18px"}}><strong>Haloween Malargue</strong></p>
                                <p className="m-0" style={{fontSize: "14px"}}>La Tosquera, Pilar</p>
                                <p className="m-0" style={{fontSize: "14px", opacity: "60%"}}>Sabado 31 de Octubre, 23:00 hs</p>
                            </div>
                        </div>
                    </div>
                </Link>
                {/*</div>*/}
        </>
    );
};

export default EventCard;