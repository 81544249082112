import React from 'react';
import ItemsContainer from "./ItemsContainer";
import './Menu.css';

const CategoriesContainer = (props) => {
    return (
        <div className="items-categ-container" id={`items-categ-container-${props.category.id}`}>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <h4 className="items-categ-title" id={`items-categ-title-${props.category.id}`}>{props.category.title}</h4>
                        </div>
                    </div>
                    {props.category.products.length > 0 ? props.category.products.map((item, index) => {
                        return (
                            <div key={index}>
                                <ItemsContainer search={props.search} item={item} />
                                {index !== props.category.products.length - 1 && <hr className="items-separator" />}
                            </div>
                        )
                    }) : (props.category.products.length === 0 && props.search) ? (
                        <div className="row">
                            <div className="col-12">
                                <p className="items-categ-no-results">No se encontraron resultados</p>
                            </div>
                        </div>
                    ) : Array.from(Array(6).keys()).map((item, index) => {
                        return (
                            <div key={index}>
                                <ItemsContainer search={props.search} />
                                {<hr className="items-separator" />}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};
export default CategoriesContainer;