import React, {useEffect, useState} from 'react';
import MenuHeader from "./MenuHeader";
import NavCategories from "./NavCategories";
import Products from "./Products";
import CarroButton from "./CarroButton";
import {motion} from "framer-motion";
import {useLocation, useNavigate} from "react-router";
import AccountHeader from "./AccountHeader";
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {CircularProgress} from "@mui/material";
import {Link} from "react-router-dom";
import {number_format} from "./functions/numberFormat";

const MyOrders = () => {

    const token = window.localStorage.getItem('token');
    const [orders, setOrders] = useState([]);
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const navigate = useNavigate();

    const formatDate = (dateString) => {
        const monthsInSpanish = [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ];

        // Convert the date string to a Date object
        // Assuming the dateString is in GMT+3
        const date = new Date(dateString + 'Z'); // Adding 'Z' to interpret it as UTC

        // Now date is in the user's local time zone
        const day = date.getDate();
        const month = monthsInSpanish[date.getMonth()];
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        // Return the formatted date string
        return `${day} ${month} ${year}, ${hours}:${minutes}`;
    };


    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/menu-orders`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "credentials": "same-origin"
            }
        });

        const response = await request.json();
        setOrders(response.data);
        setIsLoaded(true);
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchData();
        }, 200);

        // Cleanup function
        return () => {
            clearTimeout(timerId);
        };
    }, [token]);


    return (
            <div>
                <motion.div
                    initial={{
                        x: location.state?.direction === "left" ? "-100vw" : "100vw",
                    }}
                    animate={{ x: 0 }}
                    exit={{ x: "100vw" }}
                    transition={{ duration: 0.2 }}
                >
                <div style={{overflowY: "scroll", overflowX: "hidden"}}>
                    {!isLoaded ? (
                        <div className="d-flex justify-content-center align-items-center" style={{marginTop: "25vh"}}>
                            <CircularProgress color="secondary" size={100} />
                        </div>
                    ) : (
                    <div className="container-fluid">
                        <div className="row mt-3 mb-3">
                            <div className="col-12">
                                {orders.length > 0 ? (
                                    <>
                                        <div className="row">
                                            <div className="col-12">
                                                <h3 className="">Mis pedidos</h3>
                                            </div>
                                        </div>
                                        <>
                                            {orders.map((order, index) => (
                                                <div className="row" key={index}>
                                                    <div className="col-12">
                                                        <div className="order-container" onClick={() => navigate(`/order/${order?.order_hash}`)}>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <img className="order-img" src={order?.menu_image} alt=""/>
                                                                </div>
                                                                <div className="col" style={{marginLeft: 0, paddingLeft: 0}}>
                                                                    <div className="row">
                                                                        <div className="col-12">
                                                                            <p className="mb-0" style={{lineHeight: "1"}}>
                                                                                <span className="order-status-text">{order?.order_status}</span>
                                                                                <span> - </span>
                                                                                <span className="order-date-text">{formatDate(order?.order_date)}</span>
                                                                            </p>
                                                                            <p className="mb-0 order-menu-title-text">
                                                                                {order?.menu_title}
                                                                            </p>
                                                                            <p className="mb-0 order-menu-price-text">
                                                                                $ {number_format(order?.order_total)} <span className="order-menu-price-text">({`${order?.products_count} ${order?.products_count === 1 ? "producto" : "productos"}`})</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    </>
                                ) : (
                                    <div className="row">
                                        <div className="col-12">
                                            <h3 className="text-center">
                                                Aún no realizaste ningún pedido
                                            </h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                </motion.div>
            </div>
    );
};

export default MyOrders;