import React, { useState, useEffect } from 'react';
import NavbarHomePage from "./NavbarHomePage";
import { Card, CardContent, Typography } from '@material-ui/core';
import '../styles/SuccessOrder.css';
import Footer from "./Footer";

const SuccessOrder = () => {
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        setTickets([
            { ticketType: 'General', quantity: 2, price: 100, eventId: '123', eventName: 'Concierto X' },
            { ticketType: 'VIP', quantity: 1, price: 300, eventId: '123', eventName: 'Concierto X' },
        ]);
    }, []);

    const totalCost = tickets.reduce((total, ticket) => total + ticket.price * ticket.quantity, 0);
    const totalQuantity = tickets.reduce((total, ticket) => total + ticket.quantity, 0);

    return (
        <div className="spWrapper" style={{backgroundColor: "#1a1a1a"}}>
            <NavbarHomePage page={"event"} />
            <div className="container" style={{backgroundColor: "#1a1a1a", marginTop: "60px"}}>
                <div className="row pt-2 justify-content-center">
                    <div className="col-12 text-center">
                        <h1>¡Operación realizada con éxito!</h1>
                    </div>
                </div>
                <div className="row mt-4 justify-content-center">
                    <div className="col-12 text-center">
                        <h4>Resumen de tu órden:</h4>
                    </div>
                </div>
                <div className="row justify-content-center mt-2">
                    {tickets.map((ticket, index) => (
                        <div className="col-md-6 col-lg-4 mb-4" key={index}>
                            <div className="card spCard">
                                <div className="card-body">
                                    <h5 className="card-title">{`${ticket.quantity}x ${ticket.ticketType}`}</h5>
                                    <p className="card-text mb-1">Evento: {ticket.eventName} ({ticket.eventId})</p>
                                    <p className="card-text mb-1">Precio: ${ticket.price}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <p>¡Gracias por elegirnos! Te enviamos un email con tus tickets y los detalles de tu órden.</p>
                        <p>Si no recibiste el email, por favor revisá tu casilla de spam.</p>
                        <p>Si tenés alguna duda, podés contactarnos a <a style={{color: "whitesmoke"}} className="font-weight-bold" href="mailto:info@flashpass.com.ar">info@flashpass.com.ar</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SuccessOrder;