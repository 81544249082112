import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, FreeMode, Navigation} from "swiper";
import EventCard from "./EventCard";
import "swiper/css/navigation";

const EventsSwiper = (props) => {
    return (
        <>
            <div>
                {props.title && (
                <div className="row mt-3 mb-0">
                    <div className="col-12">
                        <h4 className="mb-0">{props.title}</h4>
                    </div>
                </div>
                )}
                <div className="row mb-5 align-items-center">
                    <Swiper
                        breakpoints={{
                            // when window width is >= 640px
                            0: {
                                slidesPerView: 1.4,
                                slidesOffsetBefore: 20,
                                slidesOffsetAfter: 20,
                                freeMode: true,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 4.5,
                                slidesOffsetBefore: 85,
                                slidesOffsetAfter: 85,
                                freeMode: false,
                            },
                        }}
                        spaceBetween={25}
                        navigation={true} modules={[Navigation, FreeMode]}
                        className={"pt-3"}
                    >
                        {props.events.map((event, index) => (
                            <SwiperSlide key={index}>
                                {props.theme === "dark" ? <EventCard url={event.event_url} image={event.portada} title={event.event_name} date={event.event_date} location={event.event_zone} color="dark" /> : <EventCard url={event.event_url} image={event.portada} title={event.event_name} date={event.event_date} location={event.event_zone} />}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default EventsSwiper;