// eventTracker.js

// Import necessary modules
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

// Constants for storage keys
const DEVICE_ID_COOKIE = 'device_id';
const DEVICE_ID_KEY = 'device_id';

const userToken = localStorage.getItem('token');

// Backend API URL from environment variable
const BACKEND_API_URL = process.env.REACT_APP_API_V2_URL;

// Function to generate a unique device ID
const generateDeviceId = () => {
    return uuidv4();
};

// Function to get the device ID (using Cookies)
const getDeviceId = () => {
    let deviceId = Cookies.get(DEVICE_ID_COOKIE);
    if (!deviceId) {
        deviceId = generateDeviceId();
        Cookies.set(DEVICE_ID_COOKIE, deviceId, { expires: 365 });
    }
    return deviceId;
};

// Alternatively, use Local Storage
// const getDeviceId = () => {
//   let deviceId = localStorage.getItem(DEVICE_ID_KEY);
//   if (!deviceId) {
//     deviceId = generateDeviceId();
//     localStorage.setItem(DEVICE_ID_KEY, deviceId);
//   }
//   return deviceId;
// };

// Function to send event data to the backend
const sendEventToBackend = async (payload) => {
    try {
        const response = await fetch(`${BACKEND_API_URL}/tracks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`,
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            console.error('Error tracking event:', response.statusText);
        }
    } catch (error) {
        console.error('Network error:', error);
    }
};

// Function to track events
const trackEvent = (trackName, trackData = {}) => {
    const deviceId = getDeviceId();

    const payload = {
        deviceId: deviceId,
        trackName: trackName,
        trackData: trackData
    };

    // Send the payload to the backend
    sendEventToBackend(payload);
};

// Export functions if you're using module system
export { trackEvent, getDeviceId };