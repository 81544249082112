import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import './KPI.css';
import {Link} from "react-router-dom";

const TicketContainer = (props) => {

    return (
        <div>
            <div className="row align-items-center justify-content-center p-0 m-0" style={{backgroundColor: "#4d4d4d4d", borderRadius: "10px", overflow: "hidden", color: "whitesmoke"}}>
                <div className="col-3 col-md-2 col-lg-2 p-0" style={{backgroundImage: `url(${props.ticket.image})`, objectFit: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", overflow: "hidden", aspectRatio: "10/10", maxHeight: "7.5rem"}}>
                    <img src={props.ticket.image} alt={"Nombre Evento"} className="img-fluid" style={{opacity: 0}} />
                </div>
                <div className="col-6 col-md-8 ps-2 pe-2">
                    <div className="row align-items-center p-0 m-0">
                        <div className="col-auto p-0">
                            <h3 style={{fontSize: "1.2rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} className="m-0">{props.ticket.eventName}</h3>
                        </div>
                    </div>
                    <div className="row align-items-center p-0 m-0">
                        <div className="col-auto p-0">
                            <span style={{fontSize: "0.8rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} className="m-0">{props.ticket.ticketName}</span>
                        </div>
                    </div>
                    <div className="row align-items-center p-0 m-0">
                        <div className="col-auto p-0">
                            <span style={{fontSize: "0.8rem"}} className="m-0">{props?.ticket?.date?.toLocaleString()}</span>
                        </div>
                    </div>
                </div>
                <div className="col-3 col-md-2 p-0">
                    <div className="row align-items-center justify-content-center p-2">
                        <div className="col-auto">
                            {props.ticket.menta_tokenized === 0 ? (
                                <Link to={`${props.ticket.id}`} className="btn btn-light" style={{fontSize: "0.6rem"}}>Ver Ticket</Link>
                            ) : (
                                <a className="btn btn-light" style={{fontSize: "0.6rem"}} href={`${props.ticket.id}`}>Ver Ticket</a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TicketContainer;
