import React from 'react';
import '../styles/AccountMenuOptions.css';

const AccountMenuOptions = (props) => {
    const categories = [
        {
            id: 1,
            title: 'Saldo'
        },
        {
            id: 2,
            title: 'Mis pedidos'
        },
        {
            id: 3,
            title: 'Perfil'
        },
    ];

    const handleCategoryClick = (id) => {
        props.setActiveOption(id);
    };

    return (
        <div className="flex-grow-1" style={{ overflowX: 'auto' }}>
            <div className="container-fluid">
                <div className="row text-center justify-content-center align-items-center">
                    {categories.map((category, i) => (
                        <div className={`col`} key={category.id}>
                            <div
                                className={`account-menu-element ${
                                    props.activeOption === category.id ? 'active' : null
                                }`}
                                id={`nav-categ-${category.id}`}
                            >
                                <button
                                    onClick={() => handleCategoryClick(category.id)}
                                    className="nav-categories-element-button"
                                    style={{ backgroundColor: 'transparent', border: 'none' }}
                                >
                                    <span className="account-menu-element-text">{category.title}</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AccountMenuOptions;
