import CategoriesSwiper from "./CategoriesSwiper";
import './NavCategories.css';
import {FaSearch} from "react-icons/fa";
import {useEffect} from "react";
import {IoClose} from "react-icons/io5";
import {useDispatch} from "react-redux";
import {collapseSearchBar, expandSearchBar} from "../redux/carroSlice";

const NavCategories = (props) => {

    const dispatch = useDispatch();

    const handleSearch = (e) => {
        props.setSearch(e.target.value);
    }

    useEffect(() => {
        const searchContainer = document.querySelector('.search-container');
        const searchIcon = document.querySelector('.search-icon');
        const searchInput = document.querySelector('.search-input');

        const goBackBtn = document.querySelector('#menu-go-back-btn');

        searchContainer.addEventListener('click', function() {
            searchIcon.classList.add('active');
            searchInput.classList.add('active');
            searchContainer.classList.add('expanded');
            searchInput.focus();
            dispatch(expandSearchBar());
        });

        searchInput.addEventListener('blur', function() {
            if (!searchInput.value) {
                searchIcon.classList.remove('active');
                searchInput.classList.remove('active');
                searchContainer.classList.remove('expanded');
                dispatch(collapseSearchBar());
            }
        });
    }, []);

    useEffect(() => {
        document.documentElement.style.setProperty('--menu-theme-color', props?.menu?.header_color);
    }, [props.menu]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.search]);


    return (
        <div className="nav-categories-container" id={"nav-categories-container"}>
            <div className="d-flex align-items-center">
                <div className="nav-categories-icon-container">
                    <div className="search-container">
                        <i className="fa fa-search search-icon"></i>
                        <input type="text" className="search-input" placeholder="Buscar..." onChange={handleSearch} value={props.search} />
                        <button className="clear-input" onClick={() => props.setSearch('')}><IoClose className="react-icon-close" size={25} /></button>
                    </div>
                </div>
                <div className="flex-grow-1" style={{ overflowX: 'auto' }}>
                    <CategoriesSwiper handleCategoryClick={props.handleCategoryClick} activeCategory={props.activeCategory} setActiveCategory={props.setActiveCategory} categories={props.categories} className="d-flex flex-nowrap" />
                </div>
            </div>
        </div>
    );
};

export default NavCategories;