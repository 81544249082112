import React, {useContext, useEffect, useState} from 'react';
import {IoClose} from "react-icons/io5";
import {
    Alert, Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {styled} from "@mui/system";
import {login, selectUser} from "../redux/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectMenu} from "../redux/menuSlice";
import {DeviceIdContext} from "./functions/DeviceIdProvider";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '10px',
    },
}));

const MenuAuthentication = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const menu = useSelector(selectMenu);

    const deviceId = useContext(DeviceIdContext);

    const [values, setValues] = useState({
        email: '',
        dni: '',
        phone: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
    });

    const [errors, setErrors] = useState({
        email: false,
        dni: false,
        phone: false,
        password: false,
        confirmPassword: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        email: '',
        dni: '',
        phone: '',
        password: '',
        confirmPassword: '',
    });

    const [mode, setMode] = useState('login');
    const [validForm, setValidForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');


    const handleValues = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
    };

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                break;
            case 'password':
                if (mode === "login") return;

                if (value.length < 8) {
                    error = true;
                    errorMessage = 'La contraseña debe tener al menos 8 caracteres';
                }
                if (values.confirmPassword !== '' && value !== values.confirmPassword) {
                    error = true;
                    errorMessage = 'Las contraseñas no coinciden';
                }
                break;
            case 'confirmPassword':
                if (value !== values.password) {
                    error = true;
                    errorMessage = 'Las contraseñas no coinciden';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [loginError, setLoginError] = useState(false);

    const login_api = async (e) => {
        e.preventDefault();

        try {
            var details = JSON.stringify({
                "username": values.email,
                "password": values.password,
                "login_user": true,
                "deviceId": deviceId
            });

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/authentication`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'credentials': 'same-origin',
                    },
                    body: details,
                }
            );

            if (response.ok) {
                const auth = await response.json();

                setLoginError(false);

                dispatch(login({
                    username: "",
                    password: "",
                    token: auth.auth
                }));

                window.localStorage.setItem('token', auth.auth);

                props.handleCloseConfirm();
            }
            else {
                setLoginError(true);
                setValues({...values, password: ''});
            }
        } catch (error) {
        }
    };

    const createAccount = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/register-account`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin"
            },
            body: JSON.stringify({
                ...values,
                deviceId
            })
        });

        const data = await response.json();

        if (data.status === "success") {

            dispatch(login({
                username: values.email,
                password: values.password,
                token: data.token
            }));

            window.localStorage.setItem('token', data.token);

            props.handleCloseConfirm();

        } else {
            setErrorMessage(data.message);
            setLoginError(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        const errorCheck = Object.values(errors).some((error) => error === true);
        const requiredFields = Object.values(values).some((value) => value === '');

        if (!errorCheck && !requiredFields) {
            createAccount();
        }
    }

    useEffect(() => {
        if (values.password === values.confirmPassword) {
            setErrors({...errors, confirmPassword: false, password: false});
            setErrorMessages({...errorMessages, confirmPassword: '', password: ''});
        }
        if (values.email === values.confirmEmail) {
            setErrors({...errors, confirmEmail: false, email: false});
            setErrorMessages({...errorMessages, confirmEmail: '', email: ''});
        }
    }, [values]);

    useEffect(() => {
        const errorCheck = Object.values(errors).some((error) => error === true);

        const requiredFields = Object.values(values).some((value) => value === '');

        if (!errorCheck && !requiredFields) {
            setValidForm(true);
        }
        else {
            setValidForm(false);
        }
    }, [values, errors]);

    useEffect(() => {
        if (!menu?.phone_required) {
            setValues({...values, phone: null});
        }
    }, [menu]);

    return (
        <div>
            <StyledDialog
                open={props.openConfirm}
                onClose={props.handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
            >
                <div className="closeIconLight p-1 align-items-center d-flex justify-content-center">
                    <IoClose size={25} color={"rgba(0, 0, 0, 0.38)"} onClick={props.handleCloseConfirm}/>
                </div>
                {mode === "login" ? (
                    <>
                        <DialogTitle id="responsive-dialog-title" className="text-center">
                            Iniciar Sesión
                        </DialogTitle>
                        <DialogContent>
                            {!menu?.form_phone_required ? (
                                <div className="row text-center justify-content-center align-items-center">
                                    <div className="col-12">
                                        <a href={`${process.env.REACT_APP_API_URL}/google_auth_redirection?menu=${props.id}&event=${props.event}`} className="google-btn w-100">
                                            <img
                                                src="/assets/images/google-logo.png"
                                                alt="Google icon" className="google-icon img-fluid" />
                                            Iniciar sesión con Google
                                        </a>
                                    </div>
                                </div>
                            ) : null}
                            <div className="row text-center justify-content-center align-items-center">
                                <div className="col-12">
                                    <p className="mt-2 text-muted" style={{fontSize: "12px"}}>{menu?.form_phone_required ? "I" : "o i"}nicia sesión con tu cuenta de FlashPass:</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form onSubmit={login_api}>
                                        {loginError && (
                                            <div className="mb-3">
                                                <Alert variant="filled" severity="error">
                                                    El email o la contraseña son incorrectos
                                                </Alert>
                                            </div>
                                        )}
                                        <div className="form-group mb-3">
                                            <TextField type="email" id="email" label="Email" fullWidth variant="outlined" onChange={handleValues('email')} value={values.email} autoComplete="email" required error={errors.email} helperText={errorMessages.email} />
                                        </div>
                                        <div className="form-group mb-3">
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                                <OutlinedInput
                                                    id="outlined-adornment-password"
                                                    type={values.showPassword ? 'text' : 'password'}
                                                    value={values.password}
                                                    onChange={handleValues('password')}
                                                    required
                                                    error={errors.password}
                                                    helperText={errorMessages.password}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    label="Password"
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="form-group mb-3">
                                            <button type="submit" className="btn w-100 btn-primary">Iniciar Sesión</button>
                                        </div>
                                    </form>
                                    <div className="row">
                                        <div className="col-12">
                                            <button style={{backgroundColor: "transparent", border: "none", color: "#1266f1", padding: 0, margin: 0}} onClick={() => setMode("register")}>Creá tu cuenta</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <a href={"/forgot-password"} target={"_blank"}>Olvidé mi contraseña</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </>
                ) : (
                    <>
                    <DialogTitle id="responsive-dialog-title" className="text-center">
                        Creá tu cuenta
                    </DialogTitle>
                    <DialogContent>
                        <div className="row">
                            <div className="col-12">
                                <form onSubmit={submitForm}>
                                    {loginError && (
                                        <div className="mb-3">
                                            <Alert variant="filled" severity="error">
                                                {errorMessage}
                                            </Alert>
                                        </div>
                                    )}
                                    <div className="form-group mb-3 mt-2">
                                        <TextField type="email" id="email" label="Email" fullWidth variant="outlined"
                                                   onChange={handleValues('email')} value={values.email}
                                                   autoComplete="email" required error={errors.email}
                                                   helperText={errorMessages.email}/>
                                    </div>
                                    <div className="form-group mb-3 mt-2">
                                        <TextField type="number" id="dni" label="DNI" fullWidth variant="outlined"
                                                   onChange={handleValues('dni')} value={values.dni} required
                                                   error={errors.dni} helperText={errorMessages.dni}/>
                                    </div>
                                    {menu.form_phone_required ? (
                                        <div className="form-group mb-3 mt-2">
                                            <TextField type="number" id="phone" label="Teléfono" fullWidth variant="outlined"
                                                       onChange={handleValues('phone')} value={values.phone} required
                                                       error={errors.phone} helperText={errorMessages.phone}/>
                                        </div>
                                    ) : null}
                                    <div className="form-group mb-3">
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                onChange={handleValues('password')}
                                                required
                                                error={errors.password}
                                                helperText={errorMessages.password}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Password"
                                            />
                                            {errors.password && (
                                                <p className="inputError">{errorMessages.password}</p>
                                            )}
                                        </FormControl>
                                    </div>
                                    <div className="form-group mb-3">
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Confirmar
                                                Contraseña</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={values.showPassword ? 'text' : 'password'}
                                                value={values.confirmPassword}
                                                onChange={handleValues('confirmPassword')}
                                                required
                                                error={errors.confirmPassword}
                                                helperText={errorMessages.confirmPassword}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                label="Confirmar Contraseña"
                                            />
                                            {errors.confirmPassword && (
                                                <p className="inputError">{errorMessages.confirmPassword}</p>
                                            )}
                                        </FormControl>
                                    </div>
                                    <div className="form-group mb-3">
                                        <button type="submit" className="btn w-100 btn-primary"
                                                disabled={!validForm}>Crear cuenta
                                        </button>
                                    </div>
                                </form>
                                <div className="row">
                                    <div className="col-12">
                                        <button style={{
                                            backgroundColor: "transparent",
                                            border: "none",
                                            color: "#1266f1",
                                            padding: 0,
                                            margin: 0
                                        }} onClick={() => setMode("login")}>Iniciar Sesión</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <a href={"/forgot-password"} target={"_blank"}>Olvidé mi contraseña</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    </>
                )}
            </StyledDialog>
        </div>
    );
};

export default MenuAuthentication;