import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode} from "swiper";
import EventCard from "./EventCard";

const EventsSwiper = (props) => {
    return (
        <>
            <div>
                {props.title && props.events.length > 0 && (
                <div className="row mt-3">
                    <div className="col-12">
                        <h4>{props.title}</h4>
                    </div>
                </div>
                )}
                <div className="row mb-5">
                    <Swiper
                        breakpoints={{
                            // when window width is >= 640px
                            0: {
                                slidesPerView: 1.2,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 4.2,
                            },
                        }}
                        spaceBetween={25}
                        freeMode={true}
                        modules={[FreeMode]}
                        className={"pt-3"}
                    >
                        {props.events.map((event, index) => (
                        <SwiperSlide key={index}>
                            {props.theme === "dark" ? <EventCard target={props.target} url={event.event_url} image={event.portada} title={event.event_name} date={event.event_date} location={event.event_zone} color="dark" /> : <EventCard  url={event.event_url} image={event.portada} title={event.event_name} date={event.event_date} location={event.event_zone} />}
                        </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default EventsSwiper;