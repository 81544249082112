import { createSlice } from "@reduxjs/toolkit";

export const eventChangeSlice = createSlice({
    name: "eventChange",
    initialState: {
        eventChange: {
            eventChange: false,
        }
    },
    reducers: {
        setEventChange: (state, action) => {
                state.eventChange.eventChange = action.payload;
            }
    }
});

export const { setEventChange } = eventChangeSlice.actions;

export const selectEventChange = (state) => state.eventChange.eventChange;

export default eventChangeSlice.reducer;