// DeviceIdProvider.js

import React, { createContext, useEffect, useState } from 'react';
import { getDeviceId } from './eventTracker';

export const DeviceIdContext = createContext(null);

const DeviceIdProvider = ({ children }) => {
    const [deviceId, setDeviceId] = useState(null);

    useEffect(() => {
        const id = getDeviceId();
        setDeviceId(id);
    }, []);

    return (
        <DeviceIdContext.Provider value={deviceId}>
            {children}
        </DeviceIdContext.Provider>
    );
};

export default DeviceIdProvider;
