import React, {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {BiArrowBack, BiUserCircle} from "react-icons/bi";
import {collapseSearchBar, selectCarro, selectExpandedSearchBar} from "../redux/carroSlice";
import {useDispatch, useSelector} from "react-redux";
import {AiOutlineInfoCircle} from "react-icons/ai";
import {FaRegUserCircle} from "react-icons/fa";
import {selectMenu} from "../redux/menuSlice";


const MenuHeader = (props) => {

    const navigate = useNavigate();
    const expanded = useSelector(selectExpandedSearchBar);
    const dispatch = useDispatch();
    const menu = useSelector(selectMenu);

    function handleBackButtonClick() {

        const searchContainer = document.querySelector('.search-container');
        const searchIcon = document.querySelector('.search-icon');
        const searchInput = document.querySelector('.search-input');

        if (expanded) {
            searchIcon.classList.remove('active');
            searchInput.classList.remove('active');
            searchContainer.classList.remove('expanded');
            dispatch(collapseSearchBar());
            props.setSearch('');
        } else {
            if (window.location.pathname.split("/").pop() === "carro") {
                //remove last part of url
                const pathArray = window.location.pathname.split("/");
                pathArray.pop();
                const newPathname = pathArray.join("/");
                navigate(newPathname);
            } else {
                if (window.history.length > 1) navigate(-1);
            }
        }
    }

    const menuCatSearchActive = () => {
        const pathArray = window.location.pathname.split("/");
        const lastSegment = pathArray[pathArray.length - 2];

        return lastSegment === "menu";
    }


    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div className="menu-title-container" style={{backgroundColor: menu?.header_color}}>
                        {!menuCatSearchActive.call() || expanded ? (
                            <button id={"menu-go-back-btn"} onClick={handleBackButtonClick} style={{backgroundColor: "transparent", border: "none", color: "white"}}>
                                <BiArrowBack size={25} />
                            </button>
                        ) : (
                            <div style={{width: "37.5px"}}></div>
                        )}
                        <p className="menu-title">Mi Cuenta</p>
                        <div style={{width: "37.5px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuHeader;