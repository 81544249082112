import React, {useEffect, useState} from 'react';
import {Alert, AlertTitle, CircularProgress, ThemeProvider} from "@mui/material";
import NavbarHomePage from "./NavbarHomePage";
import TicketContainer from "./TicketContainer";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";

const RenderTicket = (props) => {

    const user = useSelector(selectUser)

    const [loading, setLoading] = useState(true);
    const [ticket, setTicket] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/my-tickets?id=${props.ticket}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                "credentials": "same-origin"
            }
        });

        const data = await response.json();
        setTicket(data)
        setLoading(false);
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, [user.token]);


    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                    <div className="container p-4">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-12 p-3" style={{backgroundColor: "#4d4d4d4d", borderRadius: "1.5rem", overflow: "hidden"}}>
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="text-center">Datos del asistente</h2>
                                        <h4 className="text-center">{ticket.ticket_name}</h4>
                                        <h4 className="text-center">{ticket.ticket_dni}</h4>
                                    </div>
                                </div>
                                <div className="row mt-3 d-flex justify-content-center">
                                    <div
                                        className="col-md-6 d-flex justify-content-center justify-content-md-end mt-3 mt-md-0">
                                        <img className="img-fluid full-width"
                                             src={ticket.portada}
                                             alt={ticket.event_name} style={{width: "300px", height: "300px"}} />
                                    </div>
                                    <div className="d-block d-md-none">
                                        <hr style={{marginBottom: 0}} />
                                    </div>
                                    <div
                                        className="col-md-6 d-flex justify-content-center justify-content-md-start mt-3 mt-md-0">
                                        <img className="img-fluid full-width"
                                             src={ticket?.ticket_url}
                                             alt={props.ticket} style={{height: "300px", width: "300px"}} />
                                    </div>
                                </div>
                                <div className="row mt-5 d-flex justify-content-center">
                                    <h2 className="text-center mb-2">{ticket.event_name}</h2>
                                    <h3 className="text-center mb-2">{ticket.event_ticket_name}</h3>
                                    <h4 className="text-center mb-2">{ticket.date}</h4>
                                    <h4 className="text-center mb-2">{ticket.event_zone}</h4>
                                    <span className="text-center mt-2 mb-2">ID de compra: {ticket.reference_id}</span>
                                    <span className="text-center mb-2">ID de ticket: {ticket.ticket_id}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </div>
    );
};

export default RenderTicket;