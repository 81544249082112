import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    TextField,
    ThemeProvider
} from "@mui/material";
import NavbarHomePage from "../components/NavbarHomePage";
import 'dayjs/locale/es';
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {useNavigate} from "react-router";
import SnackbarAlert from "../components/SnackbarAlert";

const ResetPassword = (props) => {

    const user = useSelector(selectUser);
    const navigation = useNavigate();

    const [snackOpen, setSnackOpen] = useState(false);
    const [snack, setSnack] = useState({
        severity: 'success',
        message: 'Usuario creado con éxito'
    });

    const dispatch = useDispatch();

    const [validForm, setValidForm] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({
        password: false,
        confirmPassword: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        password: '',
        confirmPassword: '',
    });

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'password':
                if (value.length < 8) {
                    error = true;
                    errorMessage = 'La contraseña debe tener al menos 8 caracteres';
                }
                if (values.confirmPassword !== '' && value !== values.confirmPassword) {
                    error = true;
                    errorMessage = 'Las contraseñas no coinciden';
                }
                break;
            case 'confirmPassword':
                if (value !== values.password) {
                    error = true;
                    errorMessage = 'Las contraseñas no coinciden';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
    }

    useEffect(() => {
        const errorCheck = Object.values(errors).some((error) => error === true);

        const requiredFields = Object.values(values).some((value) => value === '');

        if (!errorCheck && !requiredFields) {
            setValidForm(true);
        }
        else {
            setValidForm(false);
        }
    }, [values]);

    const resetPassword = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/password-reset`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin"
            },
            body: JSON.stringify({
                password: values.password,
                password_confirmation: values.confirmPassword,
                token: props.token,
            })
        });

        const data = await response.json();

        if (response.status === 200) {
            setLoading(false);
            setSnack({
                severity: 'success',
                message: data.message
            })
            setSnackOpen(true);
        }

        else if (response.status === 409) {
            setLoading(false);
            setSnack({
                severity: 'error',
                message: data.message
            })
            setSnackOpen(true);
        }

        else {
            setLoading(false);
            setSnack({
                severity: 'error',
                message: 'Ha ocurrido un error'
            })
            setSnackOpen(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        resetPassword();
    }

    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <SnackbarAlert message={snack.message} severity={snack.severity} open={snackOpen} setOpen={setSnackOpen} />
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3 col-12">
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <h1>Nueva contraseña</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <form onSubmit={submitForm}>
                                            <div className="mb-3">
                                                <TextField type="password" fullWidth label="Contraseña" variant="filled" value={values?.password} onChange={handleChange('password')} error={errors.password} helperText={errorMessages.password} autoComplete="new-password" />
                                            </div>
                                            <div className="mb-3">
                                                <TextField type="password" fullWidth label="Confirmar Contraseña" variant="filled" value={values?.confirmPassword} onChange={handleChange('confirmPassword')} error={errors.confirmPassword} helperText={errorMessages.confirmPassword} autoComplete="new-password" />
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn w-100 btn-primary" type="submit" disabled={!validForm}>Enviar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </div>
    );
};

export default ResetPassword;