import React from 'react';
import {splitNumber} from "./functions/numberFormat";

const BalanceRender = (props) => {
    return (
        <>
            <p className={"mb-0 mt-0"} style={{fontSize: "1.4rem", fontWeight: "bold"}}>
                $ {splitNumber(props.balance).integer}
                <sup style={{fontSize: "0.6rem", marginLeft: "5px", top: "-8px", position: "relative"}}>
                    {splitNumber(props.balance).decimal}
                </sup>
            </p>
        </>
    );
};

export default BalanceRender;