import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import MenuHeader from './MenuHeader';
import NavCategories from './NavCategories';
import Products from './Products';
import CarroButton from './CarroButton';
import { useLocation } from 'react-router';
import AccountHeader from './AccountHeader';
import QRCode from 'qrcode.react';
import { selectUser } from '../redux/userSlice';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import AccountMenuOptions from './AccountMenuOptions';
import MyAccount from './MyAccount';
import MyOrders from "./MyOrders";
import MyProfile from "./MyProfile";

const AccountMenu = () => {
    const token = window.localStorage.getItem('token');
    const [userData, setUserData] = useState(null);
    const location = useLocation();
    const [isLoaded, setIsLoaded] = useState(false);
    const [activeOption, setActiveOption] = useState(1);

    const fetchData = async () => {
        const request = await fetch(`${process.env.REACT_APP_API_URL}/profile-users`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                credentials: 'same-origin',
            },
        });

        const response = await request.json();
        setUserData(response.profile);
        setIsLoaded(true);
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            fetchData();
        }, 200);

        // Cleanup function
        return () => {
            clearTimeout(timerId);
        };
    }, [token]);

    const pageVariants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
        },
        exit: {
            opacity: 0,
        },
    };

    return (
        <div className="menu-container" id="menu-container">
            <div className="menu-header" style={{ overflow: 'hidden' }}>
                <AccountHeader />
            </div>
            <motion.div
                initial={{
                    x: location.state?.direction === 'left' ? '-100vw' : '100vw',
                }}
                animate={{ x: 0 }}
                exit={{ x: '100vw' }}
                transition={{ duration: 0.2 }}
            >
                <div className="menu-body" id="menu-body" style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
                    <AccountMenuOptions activeOption={activeOption} setActiveOption={setActiveOption} />
                </div>
            </motion.div>
            <div>
                <motion.div
                    variants={pageVariants}
                    initial="initial"
                    animate="animate"
                    exit={{ x: '100vw' }}
                    key={activeOption}
                >
                    {activeOption === 1 ? (
                        <MyAccount />
                    ) : activeOption === 2 ? (
                        <MyOrders />
                    ) : activeOption === 3 ? (
                        <MyProfile />
                    ) : null
                    }
                </motion.div>
            </div>
        </div>
    );
};

export default AccountMenu;