import React, {useEffect, useState} from 'react';
import NavbarHomePage from "../components/NavbarHomePage";
import {Alert, AlertTitle, CircularProgress, ThemeProvider} from "@mui/material";
import TicketContainer from "../components/TicketContainer";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {useNavigate} from "react-router";

const MyTickets = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [validated, setValidated] = useState(true);
    const [email, setEmail] = useState(null);

    const user = useSelector(selectUser);

    const token = localStorage.getItem("token");

    const fetchData = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/my-tickets`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "credentials": "same-origin"
            }
        });

        const data = await response.json();
        if (data.status === "success") {
            setTickets(data.data);
            setValidated(true);
        }
        else {
            setValidated(false);
            setEmail(data.email);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (token) {
            fetchData();
        }
        else {
            navigate("/login?redirect=tickets");
        }
    }, [token]);

    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <h1>Mis Tickets</h1>
                                </div>
                            </div>
                            {!validated && (
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <Alert variant="outlined" severity="warning">
                                            <AlertTitle>Para poder ver los tickets debe confirmar su cuenta. Debes haber recibido un correo de parte nuestra al crear tu cuenta.</AlertTitle>
                                            Para reenviar el correo de verificación a su correo, haga click <a href={`${process.env.REACT_APP_API_URL}/confirm-email?email=${encodeURI(email)}`} style={{color: "rgb(255, 226, 183)"}}><b>aquí</b></a>. No te olvides de verificar la casilla de SPAM.
                                        </Alert>
                                    </div>
                                </div>
                            )}
                            {tickets.length === 0 && validated && (
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <Alert variant="outlined" severity="warning">
                                        <AlertTitle>Todavía no tenés tickets</AlertTitle>
                                        Comprá tus primeros tickets y encontralos en esta sección
                                    </Alert>
                                </div>
                            </div>
                            )}


                            {tickets.length > 0 && tickets.map((ticket, index) => (
                            <div className="row mb-3" key={index}>
                                <div className="col-12">
                                    <TicketContainer ticket={ticket} />
                                </div>
                            </div>
                            ))}


                        </div>
                    </div>
                </div>
                )}
            </ThemeProvider>
        </div>
    );


};





export default MyTickets;