import React, {useEffect, useState} from 'react';
import {
    Alert,
    AlertTitle,
    CircularProgress, FilledInput, FormControl, InputAdornment,
    InputLabel,
    MenuItem, OutlinedInput,
    Select, Snackbar,
    TextField,
    ThemeProvider
} from "@mui/material";
import NavbarHomePage from "../components/NavbarHomePage";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/es';
import {useDispatch, useSelector} from "react-redux";
import {login, selectUser} from "../redux/userSlice";
import {useLocation, useNavigate} from "react-router";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const CreateAccount = (props) => {

    const user = useSelector(selectUser);
    const navigation = useNavigate();

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const defaultEmail = query.get('email');

    useEffect(() => {
        setValues({...values, email: defaultEmail, confirmEmail: defaultEmail});
    }, []);

    const dispatch = useDispatch();

    const [validForm, setValidForm] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const [values, setValues] = React.useState({
        name: '',
        surname: '',
        email: '',
        confirmEmail: '',
        documentNumber: '',
        password: '',
        confirmPassword: '',
        showPassword: false,
        showConfirmPassword: false,
    });

    const [errors, setErrors] = useState({
        name: false,
        surname: false,
        email: false,
        confirmEmail: false,
        documentNumber: false,
        password: false,
        confirmPassword: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        name: '',
        surname: '',
        email: '',
        confirmEmail: '',
        documentNumber: '',
        password: '',
        confirmPassword: '',
    });

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        console.log(prop, value, errors, errorMessages);
        switch (prop) {
            case 'name':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El nombre debe tener al menos 2 caracteres';
                }
                break;
            case 'surname':
                if (value.length < 2) {
                    error = true;
                    errorMessage = 'El apellido debe tener al menos 2 caracteres';
                }
                break;
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                if (values.confirmEmail !== '' && value !== values.confirmEmail) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'confirmEmail':
                if (value !== values.email) {
                    error = true;
                    errorMessage = 'Los emails no coinciden';
                }
                break;
            case 'documentNumber':
                if (!value.match(/^[0-9]{7,8}$/)) {
                    error = true;
                    errorMessage = 'El DNI no es válido';
                }
                break;
            case 'password':
                if (value.length < 8) {
                    error = true;
                    errorMessage = 'La contraseña debe tener al menos 8 caracteres';
                }
                if (values.confirmPassword !== '' && value !== values.confirmPassword) {
                    error = true;
                    errorMessage = 'Las contraseñas no coinciden';
                }
                break;
            case 'confirmPassword':
                if (value !== values.password) {
                    error = true;
                    errorMessage = 'Las contraseñas no coinciden';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
    }

    useEffect(() => {
        const errorCheck = Object.values(errors).some((error) => error === true);

        const requiredFields = Object.values(values).some((value) => value === '');

        if (!errorCheck && !requiredFields) {
            setValidForm(true);
        }
        else {
            setValidForm(false);
        }
    }, [values]);

    useEffect(() => {
        if (values.password === values.confirmPassword) {
            setErrors({...errors, confirmPassword: false, password: false});
            setErrorMessages({...errorMessages, confirmPassword: '', password: ''});
        }
        if (values.email === values.confirmEmail) {
            setErrors({...errors, confirmEmail: false, email: false});
            setErrorMessages({...errorMessages, confirmEmail: '', email: ''});
        }
    }, [values]);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const createAccount = async () => {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/register-account`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "credentials": "same-origin"
            },
            body: JSON.stringify(values)
        });

        const data = await response.json();

        if (data.status === "success") {
            setLoading(false);
            setSuccessMessage(data.message);
            setOpenS(true);

            dispatch(login({
                username: values.email,
                password: values.password,
                token: data.token
            }));

            window.localStorage.setItem('token', data.token);

            setTimeout(() => {
                navigation('/index', {replace: true});
            }, 3000);

        } else {
            setLoading(false);
            setErrorMessage(data.message);
            setOpenE(true);
        }
    }

    const submitForm = (e) => {
        e.preventDefault();

        const errorCheck = Object.values(errors).some((error) => error === true);
        const requiredFields = Object.values(values).some((value) => value === '');

        if (!errorCheck && !requiredFields) {
            createAccount();
        }
    }

    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 col-12">
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <h1>Creá tu cuenta</h1>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 mt-3">
                                        <form onSubmit={submitForm}>
                                            <div className="mb-3">
                                                <TextField fullWidth label="Nombre" variant="filled" value={values?.name} onChange={handleChange('name')} error={errors.name} helperText={errorMessages.name} autoComplete="name" />
                                            </div>
                                            <div className="mb-3">
                                                <TextField fullWidth label="Apellido" variant="filled" value={values?.surname} onChange={handleChange('surname')} error={errors.surname} helperText={errorMessages.surname} autoComplete="surname" />
                                            </div>
                                            <div className="mb-3">
                                                <TextField type="email" fullWidth label="Email" variant="filled" value={values?.email} onChange={handleChange('email')} error={errors.email} helperText={errorMessages.email} autoComplete={"email"} />
                                            </div>
                                            <div className="mb-3">
                                                <TextField type="email" fullWidth label="Confirmar Email" variant="filled" value={values?.confirmEmail} onChange={handleChange('confirmEmail')} error={errors.confirmEmail} helperText={errorMessages.confirmEmail} autoComplete={"email"} />
                                            </div>
                                            <div className="mb-3">
                                                <TextField fullWidth type="number" onWheel={(e) => e.preventDefault()}  label="DNI" variant="filled" value={values?.documentNumber} onChange={handleChange('documentNumber')} error={errors.documentNumber} helperText={errorMessages.documentNumber} />
                                            </div>
                                            <div className="mb-3">
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel htmlFor="filled-adornment-password">Contraseña</InputLabel>
                                                    <FilledInput
                                                        type={values.showPassword ? 'text' : 'password'}
                                                        value={values.password}
                                                        onChange={handleChange('password')}
                                                        required
                                                        error={errors.password}
                                                        helperText={errorMessages.password}
                                                        autoComplete="new-password"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                    edge="end"
                                                                >
                                                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Password"
                                                    />
                                                    {errors.password && (
                                                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled css-xzkq1u-MuiFormHelperText-root">{errorMessages.password}</p>
                                                    )}
                                                </FormControl>
                                            </div>
                                            <div className="mb-3">
                                                <FormControl fullWidth variant="filled">
                                                    <InputLabel htmlFor="outlined-adornment-password">Confirmar Contraseña</InputLabel>
                                                    <FilledInput
                                                        id="outlined-adornment-password"
                                                        type={values.showConfirmPassword ? 'text' : 'password'}
                                                        value={values.confirmPassword}
                                                        onChange={handleChange('confirmPassword')}
                                                        required
                                                        error={errors.confirmPassword}
                                                        helperText={errorMessages.confirmPassword}
                                                        autoComplete="new-password"
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowConfirmPassword}
                                                                    onMouseDown={handleMouseDownConfirmPassword}
                                                                    edge="end"
                                                                >
                                                                    {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Contraseña"
                                                    />
                                                    {errors.confirmPassword && (
                                                        <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled css-xzkq1u-MuiFormHelperText-root">{errorMessages.confirmPassword}</p>
                                                    )}
                                                </FormControl>
                                            </div>
                                            <div className="mb-3">
                                                <button className="btn w-100 btn-primary" disabled={!validForm} type="submit">Crear Cuenta</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </div>
    );
};

export default CreateAccount;