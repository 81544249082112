import React, {useEffect, useState} from 'react';
import '../styles/FlashLinks.css';
import {useNavigate} from "react-router";
import FlashLinkContainer from "./FlashLinkContainer";

const FlashLinks = (props) => {

    const navigate = useNavigate();

    const [values, setValues] = useState({
        name: '',
        icon: '',
        links: [],
    });

    const [isLoaded, setIsLoaded] = useState(false);

    const fetchData = async () => {
        setIsLoaded(false);
        const request = await fetch(`https://api.flashpass.com.ar/event-groups?url=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        const response = await request.json();

        if (response.status === "success") {
            setValues(response?.event_group);
        } else {
            navigate("/")
        }
        setIsLoaded(true);
    }

    useEffect(() => {
        const requests = setTimeout(() => {
            fetchData();
        }, 300);
        return () => clearTimeout(requests);
    }, []);

    return (
        <div className="flashlink-container">

            {values.icon ? (
                <div className="row">
                    <div className="col-12">
                        <img className="img-link-icon" src={values.icon} />
                    </div>
                </div>
            ) : null}
            <div className="row mt-3 justify-content-center">
                <div className="col-12">
                    <h1 className="flashlink-title text-center">{values.name}</h1>
                </div>
            </div>

            <div className="row mt-5 justify-content-center">
                <div className="col-12">
                    {values.links.map((link, index) => (
                        <a href={link.url}  target="_blank" key={index} className="flashlink-link">
                            <div key={index} className="row justify-content-center align-items-center mt-3">
                                <div className="col-12">
                                    <FlashLinkContainer link={link}/>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FlashLinks;